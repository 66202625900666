import React from "react";
import MainContainer from "../../components/MainContainer";

export const ListAllItems = () => {
  return (
    <div className="list-page-container">
      <MainContainer />
    </div>
  );
};
